import { TOGGLE_SWITCH_CARDS_CONTENT } from "../actions";

const initialState = {
  isSwitch: false,
  type: "cardsTreatment",
};

export default function cardReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SWITCH_CARDS_CONTENT:
      return { ...state, isSwitch: action.payload.isSwitch, type: action.payload.type };
    default:
      return state;
  }
}
