import { API } from "../modules/Api";
import Logger from "../modules/Logger";

export const OBTAIN_TEAM_DATA = "OBTAIN_TEAM_DATA";
export const OBTAIN_TEAM_ERROR = "OBTAIN_TEAM_ERROR";
export const OBTAIN_INVITE_DATA = "OBTAIN_INVITE_DATA";
export const OBTAIN_INVITE_ERROR = "OBTAIN_INVITE_ERROR";
export const OBTAIN_TEAM_DATA_LOADING = "OBTAIN_TEAM_DATA_LOADING";

function obtainTeamData(data, nextCursor) {
  return {
    type: OBTAIN_TEAM_DATA,
    payload: { data, nextCursor },
  };
}
function obtainTeamDataLoading(teamListLoading) {
  return {
    type: OBTAIN_TEAM_DATA_LOADING,
    payload: teamListLoading,
  };
}

function obtainTeamError(error) {
  return {
    type: OBTAIN_TEAM_ERROR,
    payload: error,
  };
}
export const clearTeamList = () => dispatch => {
  dispatch(obtainTeamData([], null));
};
export const getTeamList = (page, search = "") => (dispatch, getState) => {
  dispatch(obtainTeamDataLoading(true));
  if (page === 1) {
    dispatch(clearTeamList());
  }
  const nextCursor = getState().contact.teamListNextCursor;
  return API.get("/user/contacts", { params: { cursor: nextCursor, search: search } }).then(
    response => {
      Logger.info(response.data);
      const teamList = [...getState().contact.teamList, ...response.data.results];
      dispatch(obtainTeamData(teamList, response.data.next));
      dispatch(obtainTeamDataLoading(false));
    },
    error => {
      Logger.info(error);
      dispatch(obtainTeamError(error));
    },
  );
};

// function obtainInviteData(data) {
//   return {
//     type: OBTAIN_INVITE_DATA,
//     payload: data,
//   };
// }

// function obtainInviteError(error) {
//   return {
//     type: OBTAIN_INVITE_ERROR,
//     payload: error,
//   };
// }

// export const getInviteList = page => dispatch => {
//   return API.get("/user/contactrequests").then(
//     response => {
//       Logger.info(response.data);
//       dispatch(obtainInviteData(response.data));
//     },
//     error => {
//       Logger.info(error);
//       dispatch(obtainInviteError(error));
//     },
//   );
// };

// export const contactRequest = (type, id) => dispatch => {
//   return API.post(`/user/contactrequest/${id}/${type}`).then(
//     response => {
//       Logger.info(response.data);
//       dispatch(getInviteList());
//       dispatch(getTeamList(1));
//     },
//     error => {
//       Logger.info(error);
//     },
//   );
// };

// export const deleteContact = id => dispatch => {
//   return API.post(`/user/${id}/removefromcontacts`).then(
//     response => {
//       Logger.info(response.data);
//       dispatch(getTeamList(1));
//     },
//     error => {
//       Logger.info(error);
//     },
//   );
// };
