import React, { Component, lazy } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, withRouter, Redirect, Route } from "react-router";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
// import { reconnectSocket } from "./modules/Socket";
import { PrivateRoute, OpenRoute, PublicRoute } from "./Routes";
// import LoginLayout from "./layouts/LoginLayout";
import "./App.scss";

// import moment from "moment";
// import "moment/locale/ru";

const LoginLayout = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/LoginLayout"));
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ "./layouts/MainLayout"));
const PreviewCTLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ "./layouts/PreviewLayouts/PreviewCTLayout"),
);
const PreviewROLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ "./layouts/PreviewLayouts/PreviewROLayout"),
);
const PreviewCardLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ "./layouts/PreviewLayouts/PreviewCardLayout"),
);

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          {/* <PrivateRoute path="/invoices" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/profile" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/support" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/contacts" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/cards/:id" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/cards" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/assessment" component={MainLayout} auth={this.props.isAuth} /> */}
          {/* <OpenRoute path="/invoices" component={MainLayout} auth={this.props.isAuth} /> */}
          <OpenRoute path="/profile" component={MainLayout} auth={this.props.isAuth} />
          <OpenRoute path="/company-list" component={MainLayout} auth={this.props.isAuth} />
          <OpenRoute path="/contacts" component={MainLayout} auth={this.props.isAuth} />
          <OpenRoute path="/cards/:id" component={MainLayout} auth={this.props.isAuth} />
          <OpenRoute path="/cards" component={MainLayout} auth={this.props.isAuth} />
          {/* <OpenRoute path="/assessment" component={MainLayout} auth={this.props.isAuth} /> */}
          <OpenRoute path="/preview-ct" component={PreviewCTLayout} />
          <OpenRoute path="/preview-ro" component={PreviewROLayout} />
          <OpenRoute path="/preview-card" component={PreviewCardLayout} />
          <OpenRoute path="/" component={LoginLayout} />

          <Redirect from="*" to="/profile" />
        </Switch>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  isAuth: propTypes.bool.isRequired,
};

const mapStateToProps = ({ login }) => ({
  isAuth: login.isAuth,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
