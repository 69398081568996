import {
  TOGGLE_SWITCH_PROFILE_CONTENT,
  OBTAIN_PROFILE_DATA,
  START_GET_PROFILE,
  OBTAIN_PROFILE_ERROR,
  TEST_LOGIN,
} from "../actions";
import avatar from "../assets/avatar.png";

const initialState = {
  isSwitch: false,
  isLoading: false,
  type: "editProfile",
  errors: [],
  id: 1,
  avatar: avatar,
  name: "Edgar Dorean",
  clinic: null,
  phone_number: null,
  country: null,
  shipping_address: null,
  account_type: "",
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case TEST_LOGIN:
      return { ...state, account_type: action.payload };
    case TOGGLE_SWITCH_PROFILE_CONTENT:
      return { ...state, isSwitch: action.payload.isSwitch, type: action.payload.type };
    case START_GET_PROFILE:
      return {
        ...state,
        isLoading: true,
        errors: [],
      };
    case OBTAIN_PROFILE_DATA:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        errors: [],
      };
    case OBTAIN_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
