import {
  OBTAIN_TEAM_DATA,
  OBTAIN_TEAM_ERROR,
  OBTAIN_INVITE_DATA,
  OBTAIN_INVITE_ERROR,
  OBTAIN_TEAM_DATA_LOADING,
} from "../actions";

const initialState = {
  teamList: [],
  teamListNextCursor: null,
  teamListLoading: false,
  teamListErrors: [],
  inviteList: [],
  inviteListErrors: [],
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAIN_TEAM_DATA:
      return { ...state, teamList: action.payload.data, teamListNextCursor: action.payload.nextCursor };
    case OBTAIN_TEAM_DATA_LOADING:
      return { ...state, teamListLoading: action.payload };
    case OBTAIN_TEAM_ERROR:
      return { ...state, teamListErrors: action.payload };
    case OBTAIN_INVITE_DATA:
      return { ...state, inviteList: action.payload };
    case OBTAIN_INVITE_ERROR:
      return { ...state, inviteListErrors: action.payload };

    default:
      return state;
  }
}
