import io from "socket.io-client";
import Logger from "./Logger";

let socket, socketStore;

export const getSocket = () => socket;
export const closeSocket = () => (socket ? socket.close() : null);
export const createSocketConnection = store => {
  socketStore = store;

  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications`);

  attachServiceCommands();

  return socket;
};

export const reconnectSocket = userId => {
  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications?id=${userId}`);

  // Service console
  // attachServiceCommands();
  attachAppCommands();

  return socket;
};

const attachAppCommands = () => {
  socket.on("message", data => {
    Logger.info("new socket message", data);
    Logger.info(socketStore);
  });
};

export const socketEmmit = (type, payload) => {
  if (socket) {
    socket.emit(type, payload);
  }
};

const attachServiceCommands = () => {
  // Service commands
  socket.on("connect", () => {
    Logger.info("SOCKET CONNECTION connected (openned) => ", socket);
  });
  socket.on("disconnect", () => {
    Logger.info("SOCKET CONNECTION disconnect => ", socket ? socket.connected : null);
  });
  socket.on("connect_error", error => {
    Logger.info("SOCKET connect_error => ", JSON.stringify(error));
  });
  socket.on("connect_timeout", timeout => {
    Logger.info("SOCKET connect_timeout => ", timeout);
  });
  socket.on("error", error => {
    Logger.info("SOCKET error => ", error);
  });
  socket.on("ping", () => {
    Logger.info("SOCKET ping");
  });
  socket.on("pong", latency => {
    Logger.info("SOCKET pong => ", latency);
  });

  // Reconnect
  socket.on("reconnect", attempt => {
    Logger.info("SOCKET reconnect => ", attempt);
  });
  socket.on("reconnect_attempt", res => {
    Logger.info("SOCKET reconnect_attempt => ", res);
  });
  socket.on("reconnecting", attempt => {
    Logger.info("SOCKET reconnecting => ", attempt);
  });
  socket.on("reconnect_error", error => {
    Logger.info("SOCKET reconnect_error => ", JSON.stringify(error));
  });
  socket.on("reconnect_failed", () => {
    Logger.info("SOCKET reconnect_failed => ");
  });
};
