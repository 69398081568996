import {
  TOGGLE_REFINEMENT_DIALOG,
  TOGGLE_REPLACEMENT_DIALOG,
  TOGGLE_REPLACEMENT_REQUEST_DIALOG,
  TOGGLE_CONFIRM_DIALOG,
  TOGGLE_WARRANTY_DIALOG,
  TOGGLE_UPLOAD_STL_DIALOG,
  TOGGLE_UPLOAD_SHIPING_DIALOG,
} from "../actions";

const initialState = {
  isRefinementDialog: false,
  isReplacementDialog: false,
  isReplacementRequestDialog: false,
  isConfirmDialog: false,
  isWarrantyDialog: false,
  isUploadStlDialog: false,
  isUploadShipingDialog: false,
};

export default function dialogReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_REFINEMENT_DIALOG:
      return {
        ...state,
        isRefinementDialog: action.payload.isShow,
      };
    case TOGGLE_REPLACEMENT_DIALOG:
      return {
        ...state,
        isReplacementDialog: action.payload.isShow,
      };
    case TOGGLE_REPLACEMENT_REQUEST_DIALOG:
      return {
        ...state,
        isReplacementRequestDialog: action.payload.isShow,
      };
    case TOGGLE_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmDialog: action.payload.isShow,
      };
    case TOGGLE_WARRANTY_DIALOG:
      return {
        ...state,
        isWarrantyDialog: action.payload.isShow,
      };
    case TOGGLE_UPLOAD_STL_DIALOG:
      return {
        ...state,
        isUploadStlDialog: action.payload.isShow,
      };
    case TOGGLE_UPLOAD_SHIPING_DIALOG:
      return {
        ...state,
        isUploadShipingDialog: action.payload.isShow,
      };
    default:
      return state;
  }
}
