export const TOGGLE_REFINEMENT_DIALOG = "TOGGLE_REFINEMENT_DIALOG";
export const TOGGLE_REPLACEMENT_DIALOG = "TOGGLE_REPLACEMENT_DIALOG";
export const TOGGLE_REPLACEMENT_REQUEST_DIALOG = "TOGGLE_REPLACEMENT_REQUEST_DIALOG";
export const TOGGLE_CONFIRM_DIALOG = "TOGGLE_CONFIRM_DIALOG";
export const TOGGLE_WARRANTY_DIALOG = "TOGGLE_WARRANTY_DIALOG";
export const TOGGLE_UPLOAD_STL_DIALOG = "TOGGLE_UPLOAD_STL_DIALOG";
export const TOGGLE_UPLOAD_SHIPING_DIALOG = "TOGGLE_UPLOAD_SHIPING_DIALOG";

export function toggleRefinementDialog({ isShow }) {
  return {
    type: TOGGLE_REFINEMENT_DIALOG,
    payload: { isShow },
  };
}

export function toggleReplacementDialog({ isShow }) {
  return {
    type: TOGGLE_REPLACEMENT_DIALOG,
    payload: { isShow },
  };
}

export function toggleReplacementRequestDialog({ isShow }) {
  return {
    type: TOGGLE_REPLACEMENT_REQUEST_DIALOG,
    payload: { isShow },
  };
}

export function toggleConfirmDialog({ isShow }) {
  return {
    type: TOGGLE_CONFIRM_DIALOG,
    payload: { isShow },
  };
}

export function toggleWarrantyDialog({ isShow }) {
  return {
    type: TOGGLE_WARRANTY_DIALOG,
    payload: { isShow },
  };
}

export function toggleUploadStlDialog({ isShow }) {
  return {
    type: TOGGLE_UPLOAD_STL_DIALOG,
    payload: { isShow },
  };
}

export function toggleUploadShipingDialog({ isShow }) {
  return {
    type: TOGGLE_UPLOAD_SHIPING_DIALOG,
    payload: { isShow },
  };
}
