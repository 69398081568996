export const TOGGLE_SWITCH_CARDS_CONTENT = "TOGGLE_SWITCH_CARDS_CONTENT";

export function switchCardsContent(isSwitch, type = "") {
  return dispatch => {
    dispatch(toggleSwitchCardsContent(isSwitch, type));
  };
}

function toggleSwitchCardsContent(isSwitch, type) {
  return {
    type: TOGGLE_SWITCH_CARDS_CONTENT,
    payload: { isSwitch, type },
  };
}
