import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import loginReducer from "./login";
import profileReducer from "./profile";
import cardReducer from "./card";
import dialogReducer from "./dialog";
import contactReducer from "./contact";

export default history =>
  combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    profile: profileReducer,
    card: cardReducer,
    dialog: dialogReducer,
    contact: contactReducer,
  });
